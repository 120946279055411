/* App.css or your main CSS file */
body {
  background-color: #121212; /* Dark background color */
  color: #ffffff; /* Light text color */
  font-family: Arial, sans-serif; /* Set your preferred font */
}

.map {
  background-color: #1e1e1e; /* Darker background for the map */
  border: 1px solid #888; /* Border color */
}

/* Additional styles for any buttons, inputs, etc. */
button, select {
  background-color: #333; /* Dark button background */
  color: #ffffff; /* Light text for buttons */
  border: none; /* Remove default borders */
  padding: 10px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

button:hover, select:hover {
  background-color: #555; /* Lighter background on hover */
}

