html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Lucida Grande', Geneva, Arial, Verdana, sans-serif;
    background: #fff;
}

#header {
    height: 43px;
    padding: 0;
    background-color: #eee;
    border: 1px solid #888;
}

#subheader {
    height: 12px;
    text-align: right;
    font-size: 10px;
    color: #555;
}

.custom-mouse-position {
    position: fixed; /* Use 'fixed' to position relative to the viewport */
    bottom: 10px; /* Distance from the bottom of the viewport */
    left: 10px; /* Distance from the right of the viewport */
    padding: 4px;
    border: 1px solid #888;
    background-color: #1e1e1e; /* Dark background for the coordinates box */
    color: #ffffff; /* Light text color */
    border-radius: 5px; /* Optional: Rounded corners */
    font-family: Arial, sans-serif; /* Ensure consistent font */
    z-index: 1002; /* Ensure it is above other elements */
}

.ol-popup {
    position: absolute;
    background-color: rgba(30, 30, 30, 0.5); /* Slightly transparent #1e1e1e background */
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 180px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    color: #ffffff; /* Ensure the text color contrasts well with the dark background */
}

#popup-content {
    color: #ffffff; /* White text for readability */
    padding: 10px;
    border-radius: 5px;
}

